export const RESPONSE_STATUS = {
  NotFound: {
    statusCode: 404,
    statusMessage: "Page not found",
  },
  Forbidden: {
    statusCode: 403,
    statusMessage: "Access is forbidden",
  },
  Unauthenticated: {
    statusCode: 401,
    statusMessage: "Unauthenticated",
  },
} as const;
